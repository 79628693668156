@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif,'Poppins';
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f5f8;
  --wcm-z-index: 1400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* 
::-webkit-scrollbar {
  display: none;
} */

.fa-info-circle {
  color: #45b549;
}

/* .slick-slide img {
  margin: auto;
  width:100%;
} */

.slick-dots {
  /* width: 150px !important; */
  display: flex !important;
  justify-content: space-around;
  position: relative !important;
  bottom: 3.125rem !important;
  /* left: 7vw !important; */
}

.slick-dots li button::before {
  content: '-' !important;
  background-color: #d9d9d9;
  height: 0.3rem !important;
  width: 27.5px !important;
  border-radius: 10px;
}

.slick-dots li.slick-active button::before {
  background-color: #45b549;
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}
::-webkit-scrollbar-track {
  background: #dee7fd;
}
::-webkit-scrollbar-thumb {
  background: #45b549;
}
::-webkit-scrollbar-thumb:hover {
  background: #45b549;
}
